function register(config) {
  if ('serviceWorker' in navigator) {
    const { PUBLIC_URL } = process.env;

    const {
      origin: currentOrigin,
      pathname,
      search: currentSearch,
      href: currentHref
    } = location;

    const currentPath = pathname.toLowerCase();

    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(PUBLIC_URL, currentHref);

    if (publicUrl.origin !== currentOrigin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${PUBLIC_URL}/service-worker.js`;

      const redirectToPortal = (currentPath, currentSearch) => {
        const portalOrigin = 'https://portal.castlewater.co.uk';

        const pathMap = {
            '/accountoverview': '/csm?id=csm_account_overview',
            '/info': '/csm?id=csm_account_info',
            '/premises': '/csm?id=csm_premises',
            '/meters': '/csm?id=csm_water_meters',
            '/invoices': '/csm?id=invoices',
            '/statements': '/csm?id=csm_statements'
        };

        const defaultPath = `${currentPath}${currentSearch}`;
        const portalPath = pathMap[currentPath] || defaultPath;
        const portalHref = `${portalOrigin}${portalPath}`;

        location.replace(portalHref);
      };

      navigator
        .serviceWorker
        .register(swUrl)
        .then(registration => {
            registration
              .unregister()
              .then(() => redirectToPortal(currentPath, currentSearch));

            registration.onupdatefound = () => {
              const installingWorker = registration.installing;

              if (installingWorker == null) {
                return;
              }

              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  caches.keys().then((keyList) => {
                    return Promise.all(keyList.map((key) => {
                      return caches.delete(key);
                    }));
                  });

                  if (navigator.serviceWorker.controller) {
                    // At this point, the updated precached content has been fetched,
                    // but the previous service worker will still serve the older
                    // content until all client tabs are closed.
                    console.log(
                      'New content is available and will be used when all ' +
                      'tabs for this page are closed. See https://cra.link/PWA.'
                    );

                    // Execute callback
                    if (config && config.onUpdate) {
                      config.onUpdate(registration);
                    }
                  } else {
                    // At this point, everything has been precached.
                    // It's the perfect time to display a
                    // "Content is cached for offline use." message.
                    console.log('Content is cached for offline use.');

                    // Execute callback
                    if (config && config.onSuccess) {
                      config.onSuccess(registration);
                    }
                  }
                }
              };
            };
        });
      });
  }
}

register();
